import React, {FC, ReactElement} from 'react';
import {gql, useQuery} from "@apollo/client";
import {Box, Heading, Spinner, Grid} from "grommet";
import {RibbonTile} from "./RibbonTile";
import {useFilters} from "../hooks";

const FETCH_RESULTS = gql`
    query(
        $filters: Filters
    ) {
        data: marketingPathsStatistics(
            filters: $filters
        ) {
            unique_paths
            total_paths
            conversions
            conversion_rate
            roi
            total_cost
            total_value
        }
    }
`;


export const SummaryRibbon: FC = (): ReactElement => {
    const filters = useFilters();
    const {data, loading} = useQuery(FETCH_RESULTS, {
        variables: {filters},
    });

    return (
        <Box>
            <Heading level={3}>Summary</Heading>
            {loading ? <Spinner/> :
                <Box background='light-4' pad='small' round='xsmall'>
                    <Grid gap="small" columns="small">
                        <RibbonTile title='Unique Journeys' body={data?.data?.unique_paths} fractionDigits={0}/>
                        <RibbonTile title='Total Journeys' body={data?.data?.total_paths} fractionDigits={0}/>
                        <RibbonTile title='Conversions' body={data?.data?.conversions} fractionDigits={0}/>
                        <RibbonTile title='Conversion Rate' body={data?.data?.conversion_rate} unit='%'/>
                        <RibbonTile title='Value' body={data?.data?.total_value} unit='$' unitLeft/>
                        <RibbonTile title='Cost' body={data?.data?.total_cost} unit='$' unitLeft/>
                        <RibbonTile title='ROI' body={data?.data?.roi} unit='%'/>
                    </Grid>
                </Box>}
        </Box>
    );
};