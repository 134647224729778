import React, {FC, ReactElement, useMemo} from 'react';
import {gql, useQuery} from "@apollo/client";
import {LoadingPlaceholder} from "./LoadingPlaceholder";
import {Box, Text} from "grommet";
import {HeatMapGrid} from 'react-grid-heatmap'
import {StatusWarning} from "grommet-icons";

const FETCH_MATRIX = gql`
    query {
        data: transitionMatrix {
            channel_from
            channel_to
            transition_probability
        }
    }
`;

export const TransitionMatrix: FC = (): ReactElement => {
    const {data = {}, loading, error} = useQuery(FETCH_MATRIX)

    const matrixData = useMemo(() => {
        if (!data.data) {
            return [[], [], []];
        }
        let xLabels: any = [];
        let yLabels: any = [];
        // let values: number[][] = [];
        data.data?.forEach((item: any, index: number) => {
            let xLabel = xLabels.findIndex((e: any) => e === item.channel_from);
            let yLabel = yLabels.findIndex((e: any) => e === item.channel_to);
            if (xLabel < 0) {
                xLabels.push(item.channel_from)
                xLabel = xLabels.length - 1
            }
            if (yLabel < 0) {
                yLabels.push(item.channel_to)
                yLabel = xLabels.length - 1
            }
            // values[xLabel][yLabel] = item.transition_probability
        });
        let values: number[][] = new Array(yLabels.length).fill(0).map(() => new Array(xLabels.length).fill(0));
        data.data?.forEach((item: any, index: number) => {
            let xLabel = xLabels.findIndex((e: any) => e === item.channel_from);
            let yLabel = yLabels.findIndex((e: any) => e === item.channel_to);
            values[yLabel][xLabel] = item.transition_probability
        });
        return [xLabels, yLabels, values];
    }, [data]);

    if (loading) {
        return <LoadingPlaceholder/>;
    }
    if (error) {
        return <Box><Text color='status-error' margin={{top: 'medium'}}>Something went wrong. Please remove any filters
            and try again.</Text></Box>
    }

    if (data.data?.length > 2000) {
        return <Box>
            <Text color='status-error' margin={{top: 'medium'}}>The result set
                contains <strong>{data.data?.length}</strong> records. Please narrow down your search.</Text>
        </Box>
    }

    return (
        <Box height={{min: 'large'}}>
            <Box direction="row" gap="small" align="center" margin={{vertical: `medium`}}>
                <StatusWarning color="status-warning" size="large"/>
                <Text weight="bold">Filters are not applied on the transition matrix</Text>
            </Box>
            <HeatMapGrid
                cellHeight="3rem"
                square
                data={matrixData[2]}
                xLabels={matrixData[0]}
                xLabelsStyle={() => ({transform: 'rotate(50deg)', overflow: 'visible'})}
                xLabelsPos='bottom'
                yLabels={matrixData[1]}
            />
        </Box>
    );
};