import React, {FC, ReactElement} from 'react';
import {Select, SelectProps} from "grommet";
import {gql, useQuery} from "@apollo/client";

const QUERY = gql`
    query {
        channels
    }
`;

export const ChannelSelect: FC<Omit<SelectProps, 'options'>> = (props): ReactElement => {
    const {data} = useQuery(QUERY);
    return (
        <Select {...props} options={data?.channels || []} />
    );
};