import React, {FC, ReactElement} from 'react';
import {Box, Heading, Spinner, Text, Grid} from "grommet";
import {gql, useQuery} from "@apollo/client";
import {RibbonTile} from "./RibbonTile";
import {useFilters} from "../hooks";
import {formatNumber, getChannelLabel, percentFormatter} from "../helpers";

const FETCH_ATTRIBUTION = gql`
    query($filters: Filters) {
        data: channelAttribution(
            filters: $filters
        ) {
            channel
            conversions
            value
            weight
        }
    }
`;

export const GlobalChannelAttribution: FC = (): ReactElement => {
    const filters = useFilters();
    const {data, loading} = useQuery(FETCH_ATTRIBUTION, {variables: {filters}});
    return (
        <Box>
            <Heading level={3}>Global Channel Attribution</Heading>
            {loading ? <Spinner/> :
                <Box background='light-4' pad='small' round='xsmall'>
                    <Grid columns="small" gap="small">
                        {data?.data.slice().sort((a: any, b: any) => b.value - a.value).map((channel: any) =>
                            <RibbonTile
                                key={channel.channel}
                                title={getChannelLabel(channel.channel)}
                                body={<Box>
                                    <Text>Conversions: <strong>{formatNumber(channel.conversions)}</strong></Text>
                                    <Text>Weight: <strong>{percentFormatter.format(channel.weight * 100)}</strong></Text>
                                    <Text>Value: <strong>${formatNumber(channel.value)}</strong></Text>
                                </Box>}
                            />
                        )}
                    </Grid>
                </Box>}
        </Box>
    );
};