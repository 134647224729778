import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider, createHttpLink,
} from "@apollo/client";
import {Grommet} from "grommet";
import {aruba} from "./theme";
import {BrowserRouter, Route, Routes, useLocation, Navigate} from "react-router-dom";
import {setContext} from "@apollo/client/link/context";
import {useAuth} from "./auth";
import {Login} from "./Login";

const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: 'Bearer ' + token || '',
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

function RequireAuth({ children }: { children: JSX.Element }) {
    let {isAuthenticated} = useAuth();
    let location = useLocation();

    if (!isAuthenticated) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return children;
}

ReactDOM.render(
  <React.StrictMode>
      <ApolloProvider client={client}>
          <Grommet theme={aruba} full>
              <BrowserRouter>
                  <Routes>
                      <Route path="/login" element={<Login/>}/>
                      <Route path="/" element={<RequireAuth><App /></RequireAuth>}/>
                  </Routes>
              </BrowserRouter>
          </Grommet>
      </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
