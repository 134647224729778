import React from 'react';
import {Box, Button, Header, Image, Tab, Tabs, Text} from "grommet";
import {useNavigate} from "react-router-dom";
import {ResultsTable} from "./components/ResultsTable";
import {TransitionMatrix} from "./components/TransitionMatrix";
import {Insights} from "./components/Insights";
import {Filters} from "./components/Filters";
import {SummaryRibbon} from "./components/SummaryRibbon";
import {GlobalChannelAttribution} from "./components/GlobalChannelAttribution";
import {ClearOption} from "grommet-icons";
import {PathBuilder} from "./components/PathBuilder";

function App() {
    const navigate = useNavigate();
    return (
        <Box pad='medium'>
            <Header>
                <Box width={{max: 'small'}}>
                    <Image
                        fit='contain'
                        src='//www.investopedia.com/thmb/MvVJowEtGmLG064g0GiOVptSZo0=/1200x476/filters:fill(auto,1)/Aetna-745d08dc05074965b11da0bea3329560.jpg'
                    />
                </Box>
                <Box direction="row" gap="small">
                    <Button
                        icon={<ClearOption color="status-error"/>}
                        plain
                        tip="Clear server cache"
                        onClick={async () => {
                            await fetch(`${process.env.REACT_APP_URL}/clear-cache`, {
                                method: 'POST',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                                },
                            });
                        }}/>
                    <Button label='Logout' onClick={() => {
                        localStorage.removeItem('token');
                        navigate('/login');
                    }}/>
                </Box>

            </Header>
            <Filters/>
            <SummaryRibbon/>
            <GlobalChannelAttribution/>
            <Tabs alignControls='start' margin={{top: 'small'}}>
                <Tab title={<Text size="xlarge" weight="bold">Results Viewer</Text>}>
                    <ResultsTable/>
                </Tab>
                <Tab title={<Text size="xlarge" weight="bold">Transition Matrix</Text>}>
                    <TransitionMatrix/>
                </Tab>
                <Tab title={<Text size="xlarge" weight="bold">Insights</Text>}>
                    <Insights/>
                </Tab>
                <Tab title={<Text size="xlarge" weight="bold">Path Builder</Text>}>
                    <PathBuilder/>
                </Tab>
            </Tabs>
        </Box>
    );
}

export default App;
