import React, {FC, Fragment, ReactElement} from "react";
import {Card, Text} from "grommet";
import {formatNumber} from "../helpers";

export const RibbonTile: FC<any> = ({title, body, unit = '', fractionDigits = 2, unitLeft = false}): ReactElement =>
    <Card pad='small' background="light-1">
        <Text weight='bold'>{title}</Text>
        <Text margin={{top: 'xsmall'}}>
            {React.isValidElement(body) ? body :
                <Fragment>{unitLeft && unit}{formatNumber(body, 2)}{!unitLeft && unit}</Fragment>}
        </Text>
    </Card>