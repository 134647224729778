import React, {FC, Fragment, ReactElement, useEffect, useMemo, useState} from 'react';
import {Box, ColumnConfig, DataTable, Text} from "grommet";
import {gql, useQuery} from "@apollo/client";
import {Channel, PathCell} from "./PathCell";
import {useSearchParams} from "react-router-dom";
import {LoadingPlaceholder} from "./LoadingPlaceholder";
import {useFilters} from "../hooks";
import {formatNumber} from "../helpers";
import {DownloadButton} from "./DownloadButton";

export const gradientColors = ['#8d8cff', '#ad83ff', '#c67aff', '#dc71f2', '#ed67e3', '#fb5fd2', '#ff57c0', '#ff52ad', '#ff4f9b', '#ff5089', '#ff5476', '#ff5a65', '#ff6154', '#ff6a43', '#fe7232', '#f37b1f', '#e78303', '#da8b00', '#cb9200', '#bc9900', '#ab9f00', '#99a400', '#86a900', '#72ad02', '#5ab021', '#39b336', '#00b549', '#00b75c', '#00b96f', '#00ba82', '#00bb95'];

const FETCH_PATHS = gql`
    query(
        $limit: Int!
        $offset: Int!
        $orderBy: OrderBy
        $filters: Filters
    ) {
        data: marketingPaths(
            limit: $limit
            offset: $offset
            orderBy: $orderBy
            filters: $filters
        ) {
            path {
                channel
                weight
            }
            conversions
            frequency
            conversion_rate
            value
            roi
            cost
        }
    }
`;

export interface RowType {
    path: Channel[];
    conversions: number;
    frequency: number;
    conversion_rate: number;
    value: number;
    cost: number;
    roi: number;
}

export const columns: ColumnConfig<RowType>[] = [
    {
        property: 'path',
        header: 'Path',
        sortable: true,
        render: ({path, conversions, value, cost, roi}) =>
            <PathCell conversions={conversions} path={path} value={value}/>
    },
    {
        property: 'frequency',
        sortable: true,
        header: 'Frequency',
        render: (datum) => formatNumber(datum.frequency)
    },
    {
        property: 'conversions',
        sortable: true,
        header: 'Conversions',
        render: (datum) => formatNumber(datum.conversions)
    },
    {
        property: 'conversion_rate',
        header: 'Conversion Rate',
        sortable: true,
        render: (datum) => formatNumber(datum.conversion_rate) + '%',
        units: '%'
    },
    {
        property: 'value',
        header: 'Value',
        sortable: true,
        render: (datum) => `$${formatNumber(datum.value)}`,
        units: '$'
    },
    {
        property: 'cost',
        header: 'Cost',
        sortable: true,
        render: (datum) => `$${formatNumber(datum.cost)}`,
        units: '$'
    },
    {
        property: 'roi',
        header: 'ROI',
        sortable: true,
        render: (datum) => `${formatNumber(datum.roi)}%`,
        units: '%'
    }
];

export const ResultsTable: FC = (): ReactElement => {
    const [searchParams, setSearchParams] = useSearchParams();
    const sort = useMemo(() => {
        const property = searchParams.has('orderBy') ? searchParams.get('orderBy')?.replace('-', '') : 'conversions';
        const direction = searchParams.has('orderBy') ? searchParams.get('orderBy')?.includes('-') ? 'desc' : 'asc' : 'desc';
        return {property, direction, external: true};
    }, [searchParams]);
    const [page, setPage] = useState(1);
    const [pageSize] = useState(100);
    const [results, setResults] = useState([]);
    const filters = useFilters();

    useEffect(() => {
        setResults([]);
        setPage(1);
    }, [searchParams]);
    const {loading} = useQuery(FETCH_PATHS, {
        variables: {
            filters,
            limit: pageSize,
            offset: (page - 1) * pageSize,
            orderBy: {
                column: sort.property,
                order: sort.direction
            },
        },
        fetchPolicy: 'no-cache',
        onCompleted: data => setResults(results => results.concat(data.data))
    });

    return (
        <Fragment>
            <Box direction='row' gap='medium' justify='between' align='center'>
                <Box margin={{top: 'medium'}} direction='row-responsive' align='center' gap='medium'>
                    <Text weight='bold'>Attribution Legend</Text>
                    <Box direction='row' gap='xsmall'>
                        <Text>0%</Text>
                        <Box round='small' width='medium' height='20px'
                             background={`linear-gradient(90deg, ${gradientColors.join()})`}/>
                        <Text>100%</Text>
                    </Box>
                </Box>
                <DownloadButton data={results}/>
            </Box>
            <DataTable
                fill
                pin
                onSort={({property, direction}) => {
                    searchParams.set('orderBy', direction === 'asc' ? property : `-${property}`);
                    setSearchParams(searchParams);
                }}
                // @ts-ignore
                sort={sort}
                step={pageSize - 1}
                border='bottom'
                columns={columns}
                onMore={() => {
                    setPage(page => page + 1);
                    console.log('load more')
                }}
                placeholder={loading ?
                    <LoadingPlaceholder/> :
                    results.length === 0 ?
                        <Box align='start' margin={{top: 'small', left: 'small'}}>
                            <Text size='large'>No results found.</Text>
                        </Box> :
                        undefined}
                data={results}
                primaryKey='path'
            />
        </Fragment>
    );
};