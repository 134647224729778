import {useSearchParams} from "react-router-dom";
import {useMemo} from "react";

const excludeParams = ['page', 'pageSize', 'orderBy'];

export const useFilters = () => {
    const [searchParams] = useSearchParams();

    return useMemo(() => {
        let filters: { [key: string]: { [key: string]: string } } = {};
        searchParams.forEach((value, key) => {
            const [field, operator] = key.split('__')
            if (!excludeParams.includes(field)) {
                filters[field] = {
                    ...filters[field],
                    [operator]: value
                };
            }
        });
        return filters;
    }, [searchParams]);
}