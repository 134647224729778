import React, {FC, ReactElement, useState} from 'react';
import {Box, DataTable, Heading, Select, Text} from "grommet";
import {gql, useQuery} from "@apollo/client";
import {LoadingPlaceholder} from "./LoadingPlaceholder";
import {columns} from "./ResultsTable";
import {useFilters} from "../hooks";

const FETCH_PATHS = gql`
    query(
        $limit: Int!
        $filters: Filters
    ) {
        most_frequent: marketingPaths(
            limit: $limit
            filters: $filters
            orderBy: {column: "frequency" order: desc}
        ) {
            path {
                channel
                weight
            }
            conversions
            frequency
            conversion_rate
            value
            roi
            cost
        }
        most_conversions: marketingPaths(
            limit: $limit
            filters: $filters
            orderBy: {column: "conversions" order: desc}
        ) {
            path {
                channel
                weight
            }
            conversions
            frequency
            conversion_rate
            value
            roi
            cost
        }
        highest_roi: marketingPaths(
            limit: $limit
            filters: $filters
            orderBy: {column: "roi" order: desc}
        ) {
            path {
                channel
                weight
            }
            conversions
            frequency
            conversion_rate
            value
            roi
            cost
        }
        highest_cost: marketingPaths(
            limit: $limit
            filters: $filters
            orderBy: {column: "cost" order: desc}
        ) {
            path {
                channel
                weight
            }
            conversions
            frequency
            conversion_rate
            value
            roi
            cost
        }
    }
`;

const headingMap: any = {
    most_frequent: 'Most Frequent Paths',
    most_conversions: 'Most Converting Paths',
    highest_roi: 'Highest ROI Paths',
    highest_cost: 'Highest Cost Paths'
}

const OPTIONS = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

export const Insights: FC = (): ReactElement => {
    const [limit, setLimit] = useState(5);
    const filters = useFilters();

    const {data = {}, loading} = useQuery(FETCH_PATHS, {
        variables: {
            limit,
            filters
        },
        fetchPolicy: 'no-cache',
    });

    if (loading) {
        return <LoadingPlaceholder/>
    }

    return (
        <Box>
            <Box direction='row' gap='small' alignSelf='end' align='center' height={{min: 'xxsmall'}}>
                <Text>Results per table</Text>
                <Box width='xsmall'>
                    <Select
                        options={OPTIONS}
                        // @ts-ignore
                        value={limit}
                        onChange={({value}) => setLimit(value)}
                    />
                </Box>
            </Box>
            {Object.keys(data).map(key => <Box key={key} flex={false}><Heading
                level={3}>{headingMap[key]}</Heading><DataTable
                border='bottom'
                columns={columns}
                placeholder={
                    data[key].length === 0 ?
                        <Box align='start' margin={{top: 'small', left: 'small'}}>
                            <Text size='large'>No results found.</Text>
                        </Box> :
                        undefined}
                data={data[key]}
                primaryKey='path'
            /></Box>)}
        </Box>);
};