import React, {FC, ReactElement, useState} from 'react';
import {gql, useMutation} from "@apollo/client";
import {Box, Button, FormField, Spinner, Text} from "grommet";
import {ChannelSelect} from "./ChannelSelect";
import {StepsDiagram} from "./StepsDiagram";

const MUTATION = gql`
    mutation(
        $path: [String]!
    ) {
        mostCommonNextStep(path: $path)
    }
`;


export const MatrixPathBuilder: FC = (): ReactElement => {
    const [steps, setSteps] = useState<string[]>([]);
    const [mostCommonNextStep, {data, loading, error}] = useMutation(MUTATION);
    return (
        <Box>
            <FormField label="Add Channel" contentProps={{alignSelf: 'start', border: false}}>
                <ChannelSelect
                    value=""
                    onChange={({value}) => setSteps(oldSteps => oldSteps.concat([value]))}
                />
            </FormField>
            {steps.length > 0 && <Text margin={{horizontal: 'small'}}>Generated Path</Text>}
            <StepsDiagram
                steps={steps}
                removeStep={(index) => setSteps(oldSteps => oldSteps.filter((_, i) => i !== index))}
                idPrefix="matrix-step"
            />
            <Box direction="row" gap="medium" align="center">
                <Button disabled={steps.length > 9} primary label="Predict next steps" alignSelf="start"
                        onClick={async () => {
                            const {data} = await mostCommonNextStep({variables: {path: steps}});
                            if (['conv', 'null'].includes(data.mostCommonNextStep)) {
                                return;
                            }
                            setSteps(oldSteps => oldSteps.concat([data.mostCommonNextStep.toUpperCase()]));
                        }

                        }/>
                {loading && <Spinner/>}
                {error && <Text color="status-critical">Something went wrong. Please try again.</Text>}
                {steps.length > 9 && <Text color="status-warning">You have reached the maximum number of steps.</Text>}
                {['conv', 'null'].includes(data?.mostCommonNextStep) && <Text color="status-ok">Model suggest to stop here.</Text>}
            </Box>
        </Box>
    );
};