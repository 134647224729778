import React, {FC, ReactElement} from "react";
import {Box, Spinner, Text} from "grommet";

export const LoadingPlaceholder: FC = (): ReactElement => (
    <Box
        fill
        align="center"
        justify="end"
        pad="large"
        height={{min: 'medium'}}
        background={{color: 'background-front', opacity: 'strong'}}
    >
        <Box direction="row" gap="small" align='center'>
            <Spinner size='large'/>
            <Text weight="bold">Loading ...</Text>
        </Box>
    </Box>
)