import React, {FC, ReactElement, useMemo} from 'react';
import {Box, Diagram, Stack, Text, Tip} from "grommet";
import {gradientColors} from "./ResultsTable";
import {amountFormatter, numberFormatter, percentFormatter} from "../helpers";

const newId = () => {
    let S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
};

export const stringToColour = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

export interface Channel {
    channel: string
    weight: number
}

const transform = (steps: Channel[]): any => {
    let pathAgg: any = []
    if (!steps.length) {
        return []
    }
    let stepCounter = 0;
    let lastStep = steps[0];
    steps.forEach(step => {
        if (step.channel === lastStep.channel) {
            stepCounter++;
        } else {
            pathAgg.push({step: lastStep, count: stepCounter, id: newId()});
            stepCounter = 1;
            lastStep = step;
        }
    })
    pathAgg.push({step: lastStep, count: stepCounter, id: newId()});
    return pathAgg;
}

interface Props {
    path: Channel[]
    conversions: number
    value: number
}

export const PathCell: FC<Props> = ({path, conversions, value}): ReactElement => {
    const data = useMemo(() => transform(path), [path]);
    return (
        <Stack guidingChild={1}>
            <Diagram
                connections={data.slice(0, -1).map((step: any, index: number) => ({
                    fromTarget: step.id,
                    toTarget: data[index + 1].id,
                    thickness: 'xsmall',
                    color: stringToColour(step.id),
                    type: 'rectilinear',
                }))}
            />
            <Box direction='row' wrap>
                {data.map((step: any) =>
                    <Tip
                        dropProps={{background: 'light-6', round: 'small'}}
                        plain
                        key={step.id}
                        content={
                            <Box pad='small'>
                                <Text>
                                    Attribution Weight: <strong>{percentFormatter.format(step.step.weight * 100)}</strong>
                                    <br/>
                                    Conversions Attributed: <strong>{numberFormatter.format(step.step.weight * conversions)}</strong>
                                    <br/>
                                    Value attributed: <strong>{amountFormatter.format(step.step.weight * value)}</strong>
                                </Text>
                            </Box>}
                    >
                    <Box
                        id={step.id}
                        margin='small'
                        pad='small'
                        style={{cursor: 'pointer'}}
                        background={conversions < 1 ? gradientColors.at(0) : data.length === 1 ? gradientColors.at(-1) : gradientColors[Math.round(step.step.weight * (gradientColors.length - 1))]}
                        round='small'
                        width={{max: '200px'}}
                    >
                            <Text><strong>{step.count}</strong> x {step.step.channel}</Text>
                    </Box>
                    </Tip>
                )}
            </Box>
        </Stack>
    );
};