import React, {FC, ReactElement, useCallback, useState} from 'react';
import {Box, Button, Image, TextInput, Text} from "grommet";
import {useNavigate} from "react-router-dom";


export const Login: FC = (): ReactElement => {
    const navigate = useNavigate();
    const [token, setToken] = useState('');
    const [error, setError] = useState<string | null>(null);
    const login = useCallback(async () => {
        setError(null);
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/login`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({token})
            })
            if (response.status !== 204) {
                throw new Error();
            }
            localStorage.setItem('token', token);
            navigate('/', {replace: true});
        } catch {
            setError('Invalid token');
        }
    }, [token, navigate]);
    return (
        <Box fill pad='medium' flex={false} align='center' justify='center'>
            <Box width={{max: 'medium'}}>
                <Image fit='contain' src='//www.investopedia.com/thmb/MvVJowEtGmLG064g0GiOVptSZo0=/1200x476/filters:fill(auto,1)/Aetna-745d08dc05074965b11da0bea3329560.jpg'/>
                <Box gap='small' align='center'>
                    <TextInput placeholder='Authentication Token' value={token} onChange={(event: any) => setToken(event.target.value)}/>
                    {error && <Text alignSelf='start' color='status-error'>{error}</Text>}
                    <Button primary label='Login' onClick={login}/>
                </Box>
            </Box>
        </Box>
    );
};