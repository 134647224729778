import React, {FC, ReactElement, useMemo} from 'react';
import {Box, Diagram, Stack, Tag} from "grommet";
import {stringToColour} from "./PathCell";

interface Props {
    steps: string[];
    removeStep: (index: number) => void;
    idPrefix?: string;
}

export const StepsDiagram: FC<Props> = ({steps, removeStep, idPrefix= `step`}): ReactElement => {
    const connections = useMemo(() => steps.slice(0, -1).map((step: any, index: number) => ({
        fromTarget: `${idPrefix}-${index}`,
        toTarget: `${idPrefix}-${index + 1}`,
        thickness: 'xsmall',
        color: 'brand',
        animation: index === steps.length - 2 ? 'draw' : undefined,
        type: 'rectilinear',
    })), [steps, idPrefix]);
    return (
        <Stack guidingChild={1} margin={{vertical: "medium"}}>
            <Diagram
                // @ts-ignore
                connections={connections}
            />
            <Box direction="row" wrap>
                {steps.map((step, index) => (
                    <Box key={index} id={`${idPrefix}-${index}`} margin="medium"
                         background={stringToColour(step)} round="large"><Tag
                        value={step}
                        key={index}
                        onRemove={() => removeStep(index)}
                    /></Box>
                ))}
            </Box>
        </Stack>
    );
};