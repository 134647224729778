import React, {FC, Fragment, ReactElement, useRef} from 'react';
import {CSVLink} from "react-csv";
import {Button} from "grommet";
import {Channel} from "./PathCell";

interface Props {
    data: any[]
}

export const DownloadButton: FC<Props> = ({data}): ReactElement => {
    const csvLink = useRef<any>()
    return (
        <Fragment>
            <Button
                label='Export'
                alignSelf='end'
                primary
                onClick={() => csvLink.current.link.click()}
                margin={{vertical: 'small'}}
            />
            <CSVLink
                ref={csvLink}
                filename='export.csv'
                style={{display: 'none'}}
                target="_blank"
                data={data.map(({__typename, ...row}: any) => ({
                    ...row,
                    path: row.path.map((step: Channel) => step.channel).join(' > ')
                }))}
            />
        </Fragment>

    );
};