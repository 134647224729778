export const amountFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    unitDisplay: 'short',
    maximumFractionDigits: 2,
});

export const percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'percent',
    unitDisplay: 'short',
    maximumFractionDigits: 2
});

export const numberFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2
})

export const getChannelLabel = (touch: string) => {
    switch(touch) {
        case 'AD':
            return 'Aetna Digital'
        case 'DM':
            return 'Direct Mail';
        case 'DG':
            return 'Digital';
        case 'EM':
            return 'Email';
        case 'IVR':
            return 'Interactive Voice Response';
        case 'SMS':
            return 'SMS';
        case 'TM':
            return 'Outbound Telesales';
        default:
            return touch;
    }
}

const formatSmallNumbers = (n: any, fractionDigits = 2) => {
    const formatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: fractionDigits
    });
    if (n) return formatter.format(n);
    return 0;
}

export const formatNumber = (num: any, digits: number = 2) => {
    if (num < 1) {
        return formatSmallNumbers(num, digits);
    }
    const lookup = [
        {value: 1, symbol: ""},
        {value: 1e3, symbol: "k"},
        {value: 1e6, symbol: "M"},
        {value: 1e9, symbol: "B"},
        {value: 1e12, symbol: "T"},
        {value: 1e15, symbol: "P"},
        {value: 1e18, symbol: "E"}
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup.slice().reverse().find(function (item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}