import React, {FC, ReactElement, useCallback, useState} from 'react';
import {createSearchParams, useSearchParams} from "react-router-dom";
import {Box, Button, DateInput, Form, FormField, Grid, Heading, Select, Spinner, TextInput} from "grommet";
import {gql, useQuery} from "@apollo/client";

const FILTERS = gql`
    query  {
        products
        markets
        subMarkets {
            subMarket
            market
        }
        lineOfBusinesses
        campaignTimings
    }
`;

export const Filters: FC = (): ReactElement => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {data, loading} = useQuery(FILTERS);
    const setInitialValues = useCallback(() => ({
        lineOfBusiness__eq: searchParams.get('lineOfBusiness__eq') || '',
        market__eq: searchParams.get('market__eq') || '',
        subMarket__eq: searchParams.get('subMarket__eq') || '',
        product__eq: searchParams.get('product__eq') || '',
        campaignTiming__eq: searchParams.get('campaignTiming__eq') || '',
        startDate__gte: searchParams.get('startDate__gte') || '',
        endDate__lte: searchParams.get('endDate__lte') || '',
        effYr__eq: searchParams.get('effYr__eq') || '',
        roi__gte: searchParams.get('roi__gte') || '',
        roi__lte: searchParams.get('roi__lte') || '',
        cost__gte: searchParams.get('cost__gte') || '',
        cost__lte: searchParams.get('cost__lte') || '',
        value__gte: searchParams.get('value__gte') || '',
        value__lte: searchParams.get('value__lte') || '',
        conversion__gte: searchParams.get('conversion__gte') || '',
        conversion__lte: searchParams.get('conversion__lte') || '',
        frequency__gte: searchParams.get('frequency__gte') || '',
        frequency__lte: searchParams.get('frequency__lte') || '',
    }), [searchParams]);
    const [value, setValue] = useState(setInitialValues());
    return (
        <Box flex={false} gap='small'>
            <Form
                value={value}
                onReset={() => {
                    setSearchParams(createSearchParams());
                    setValue({
                        lineOfBusiness__eq: '',
                        market__eq: '',
                        subMarket__eq: '',
                        product__eq: '',
                        campaignTiming__eq: '',
                        startDate__gte: '',
                        endDate__lte: '',
                        effYr__eq: '',
                        roi__gte: '',
                        roi__lte: '',
                        cost__gte: '',
                        cost__lte: '',
                        value__gte: '',
                        value__lte: '',
                        conversion__gte: '',
                        conversion__lte: '',
                        frequency__gte: '',
                        frequency__lte: '',
                    });
                }}
                onChange={(nextValue) => setValue(nextValue)} onSubmit={(event) => {
                setSearchParams(createSearchParams(Object.keys(event.value).reduce((acc: any, key) => {
                    // @ts-ignore
                    if (event.value[key]) {
                        // @ts-ignore
                        acc[key] = event.value[key];
                    }
                    return acc;
                }, {})));
            }}>
                <Heading level={3}>Audience Filters</Heading>
                <Grid columns="small" gap="small" fill="horizontal">
                    <FormField name='lineOfBusiness__eq' label="Line of Business">
                        <Select
                            clear={{position: 'top', label: 'Clear selection'}}
                            name="lineOfBusiness__eq"
                            disabled={loading}
                            icon={loading ? <Spinner /> : undefined}
                            options={data?.lineOfBusinesses || []}
                        />
                    </FormField>
                    <FormField name='campaignTiming__eq' label='Campaign Timing'>
                        <Select
                            clear={{position: 'top', label: 'Clear selection'}}
                            name="campaignTiming__eq"
                            disabled={loading}
                            icon={loading ? <Spinner /> : undefined}
                            options={data?.campaignTimings || []}
                        />
                    </FormField>
                    <FormField name='product__eq' label='Product'>
                        <Select
                            clear={{position: 'top', label: 'Clear selection'}}
                            name="product__eq"
                            disabled={loading}
                            icon={loading ? <Spinner /> : undefined}
                            options={data?.products || []}
                        />
                    </FormField>
                    <FormField name='market__eq' label='Market'>
                        <Select
                            clear={{position: 'top', label: 'Clear selection'}}
                            name="market__eq"
                            disabled={loading}
                            icon={loading ? <Spinner /> : undefined}
                            options={data?.markets || []}
                        />
                    </FormField>
                    <FormField name='subMarket__eq' label='Sub-Market'>
                        <Select
                            clear={{position: 'top', label: 'Clear selection'}}
                            name="subMarket__eq"
                            disabled={loading}
                            icon={loading ? <Spinner /> : undefined}
                            valueKey={{key: 'subMarket', reduce: true}}
                            labelKey="subMarket"
                            options={data?.subMarkets.filter(({market}: any) => value.market__eq ? market === value.market__eq: true) || []}
                        />
                    </FormField>
                    <FormField name='startDate__gte' label='Start Date'>
                        <DateInput
                            name="startDate__gte"
                            width="medium"
                            format='mm/dd/yyyy'
                        />
                    </FormField>
                    <FormField name='endDate__lte' label='End Date'>
                        <DateInput
                            name="endDate__lte"
                            width="medium"
                            format='mm/dd/yyyy'
                        />
                    </FormField>
                    <FormField name='effYr__eq' label='Effective Year'>
                        <Select
                            clear={{position: 'top', label: 'Clear selection'}}
                            name="effYr__eq"
                            options={['2020', '2021', '2022']}
                        />
                    </FormField>
                </Grid>
                <Heading level={3}>Path Level Filters</Heading>
                <Grid columns="small" gap="small" fill="horizontal">
                    <FormField name='roi__gte' label='Min ROI'>
                        <TextInput name='roi__gte' icon={<span>%</span>}/>
                    </FormField>
                    <FormField name='roi__lte' label='Max ROI'>
                        <TextInput name='roi__lte' icon={<span>%</span>}/>
                    </FormField>
                    <FormField name='cost__gte' label='Min Cost'>
                        <TextInput name='cost__gte' icon={<span>$</span>}/>
                    </FormField>
                    <FormField name='cost__lte' label='Max Cost'>
                        <TextInput name='cost__lte' icon={<span>$</span>}/>
                    </FormField>
                    <FormField name='value__gte' label='Min Value'>
                        <TextInput name='value__gte' icon={<span>$</span>}/>
                    </FormField>
                    <FormField name='value__lte' label='Max Value'>
                        <TextInput name='value__lte' icon={<span>$</span>}/>
                    </FormField>
                    <FormField name='conversion__gte' label='Min Conversions'>
                        <TextInput name='conversions__gte'/>
                    </FormField>
                    <FormField name='conversion__lte' label='Max Conversions'>
                        <TextInput name='conversions__lte'/>
                    </FormField>
                    <FormField name='frequency__gte' label='Min Frequency'>
                        <TextInput name='frequency__gte'/>
                    </FormField>
                    <FormField name='frequency__lte' label='Max Frequency'>
                        <TextInput name='frequency__lte'/>
                    </FormField>
                </Grid>
                <Box direction="row" gap="small">
                    <Button
                        type="submit"
                        label="Apply filters"
                        margin={{top: 'medium'}}
                        primary
                    />
                    <Button
                        label="Clear filters"
                        margin={{top: 'medium'}}
                        secondary
                        type="reset"
                    />
                </Box>
            </Form>
        </Box>
    );
};