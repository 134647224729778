import React, {FC, ReactElement} from 'react';
import {Box, Text} from "grommet";
import {RnnPathBuilder} from "./RNNPathBuilder";
import {MatrixPathBuilder} from "./MatrixPathBuilder";

export const PathBuilder: FC = (): ReactElement => (
    <Box gap="medium" margin={{top: 'medium'}}>
        <Text size="large" weight="bold">RNN Path Builder</Text>
        <RnnPathBuilder/>
        <Text size="large" weight="bold">Matrix Path Builder</Text>
        <MatrixPathBuilder/>
    </Box>
);